<template>
  <div>
    <p>Dev Examples</p>
  </div>
</template>
<script>
export default {
  name: "DevView",
};
</script>
